import './reservationNotices.styles.scss';


const ReservationNotice = () => {
  return (
    <div id="reservation-notice">
      <div className='reservation-warpper'>
        <h2 className='reservation-title'>Reservation Policy</h2>
        <p className='reservation-policy-copy'>
        Reservation for 2025 (July to December) 
        Omakase kaiseki is opening in March.25th between 11am to 3pm.
        We are closed March.25th but we take reservation through phone calls only and ongoing on our business day. Please leave a voicemail If we are unable to answer.
        
        <br/><br/>
        <strong>Please read before making reservations:</strong><br/>
        We accept reservations Wednesday to Sundays. We are closed Mondays and Tuesdays
        Max 2 reservations for the same group.
        Please pick the date carefully. If the number of guests in your party changes, we may need to ask you to a different date or time.    
        We are unable to accommodate any food allergies or restrictions. We don’t have Vegetarian, Gluten free options.
        
        <br/><br/>
        After the booking
          <ol>
            <li>You will receive a confirmation message by text about a week prior. Please reply to re-confirm the reservation. Please contact us as soon as possible If you do not receive any message. Our standard practice is to make a re-confirmation 3 to 7 days prior. The reservation will be canceled If you cannot confirm 2 days prior. We do not charge deposit/change/cancellation fee so please ensure you re-confirm your reservation 3 to 7 days prior.</li>
            <li>If the number of guests in your party changes, we may need to ask you to a different date or time. Please notify us as soon as possible so that we can accommodate accordingly.</li>
            <li>We kindly request you to arrive punctually for your reservation. Please be aware If you are 15minutes late we may cancel your reservation.</li>
          </ol>
        </p>
      </div>

        <div className="copyright-text">© YUGAFU JAPANESE BISTRO</div>
    </div>
  )
}

export default ReservationNotice;
